<template>
  <div class="background">
    <div class="dark-layer" />

    <nuxt-link :to="logoUrl" class="logo">
      <div class="logo-icon" />
      <strong class="logo-text">
        RISER<br />
        <small>DRIVEN BY ADVENTURE</small>
      </strong>
    </nuxt-link>

    <div class="centered-container">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Free',
  computed: {
    logoUrl() {
      const authStore = useAuthStore(this.$pinia)

      return authStore.session_data.token ? '/feed' : '/'
    }
  }
}
</script>

<style lang="sass" scoped>
@import "@/assets/vars.sass" // SASS variables

.dark-layer
  position: absolute
  height: 100%
  width: 100%
  opacity: 0.4
  background-color: #000

.centered-container
  position: relative
  display: flex
  height: 100vh
  width: 100%
  justify-content: center
  align-items: center

.background
  position: absolute
  min-height: 100%
  width: 100%
  padding: 0.1px
  background-image: url("/free_layout_backgrounds/free_landscape_1366.jpg")
  background-attachment: fixed
  background-position: center
  background-size: cover
@media (min-width: 1960px), (min-height: 1200px)
  .background
    background-image: url("/free_layout_backgrounds/free_landscape_2560.jpg")

.logo
  position: absolute
  display: flex
  align-items: center
  margin: 30px
  color: rgb(var(--secondary-background-color))
  z-index: 1
  font-size: 1.2rem
  line-height: 0.9em
  small
    line-height: 0.75em
    font-size: 0.75em
.logo-icon
  height: 3.5em
  width: 3.5em
  background-image: url("/logo.svg")
  background-size: auto 3em
  background-repeat: no-repeat
  background-position: center

@media (max-height: 600px)
  .logo
    position: relative

  .centered-container
    display: block
    height: auto
    margin-bottom: $defaultMargin
</style>
